import { getLocalStorage } from '@shein/common-function'
import { onWindowLoad } from 'public/src/pages/common/utils/index.js'

function range(num, min, max) {
  return Math.min(Math.max(num, min), max)
}

let firstCateScroll = {
  defaultIndex: null,

  elasticity: 100,
  floatMargin: 10,
  arrowWidth: 42,

  $refs: null,

  showArrow: false,

  itemsOffsetX: [],     // 每个item的偏移量
  wrapperStyle: {},
  minTransformX: 0,
  maxTransformX: 0,

  columnOffsetX: 0,     // 偏移量
  columnOffsetIndex: 0, // 吸附位置
  getDom() {
    let cookieChannelType = getLocalStorage('cate_channel_type')
    this.defaultIndex = parseInt(document.querySelector(`.j-nav-first-cate-${cookieChannelType}`) && document.querySelector(`.j-nav-first-cate-${cookieChannelType}`).dataset.index || 0)
    this.$refs = {
      activeItem: document.querySelector(`.j-nav-first-cate-${cookieChannelType}`) || document.querySelector('.j-nav-first-cate-index0'),
      headerArrowL: document.querySelector('.j-cm-header-arrow-l'),
      headerArrowR: document.querySelector('.j-cm-header-arrow-r'),
      rollExtraOuter: document.querySelector('.j-cm-header-left-outer'),
      rollOuter: document.querySelector('.j-cm-header-left-inner'),
      rollContainer: document.querySelector('.j-cm-first-cate-ctn')
    }
  },
  mounted() {
    this.setObserveData()
    this.setDefault()
    this.addWindowResizeListener()
  },

  setObserveData() {
    let self = this
    let wrapperStyle
    Object.defineProperty(self, 'wrapperStyle', {
      get: function () {
        return wrapperStyle
      },
      set: function (val) {
        wrapperStyle = val
        self.$refs['rollContainer'].style.transition = val.transition
        self.$refs['rollContainer'].style.transform = val.transform

        // 展示左箭头
        if (
          (!gbCommonInfo.GB_cssRight && val.x < 0) ||
          (gbCommonInfo.GB_cssRight && val.x > 0)
        ) {
          self.$refs['headerArrowL'].style.display = 'block'
          self.$refs['rollExtraOuter'].style[gbCommonInfo.GB_cssRight ? 'paddingRight' : 'paddingLeft'] = self.arrowWidth + self.floatMargin + 'px'
        } else {
          self.$refs['headerArrowL'].style.display = 'none'
          self.$refs['rollExtraOuter'].style[gbCommonInfo.GB_cssRight ? 'paddingRight' : 'paddingLeft'] = self.floatMargin + 'px'
        }

        // 展示右箭头
        if (
          (!gbCommonInfo.GB_cssRight && val.x > self.minTransformX) ||
          (gbCommonInfo.GB_cssRight && val.x < self.minTransformX)
        ) {
          self.$refs['headerArrowR'].style.display = 'block'
        } else {
          self.$refs['headerArrowR'].style.display = 'none'
        }
      }
    })
  },
  setDefault () {
    this.showArrow = false
    this.columnOffsetX = 0
    this.minTransformX = 0
    this.setWrapperStyle(0)
    this.itemsOffsetX.splice(0)

    this.initTransformData(true)

    this.$refs['rollContainer'].style.visibility = 'visible'
  },
  initTransformData(doDefaultScroll) {
    let outerWidth = this.$refs['rollOuter'].getBoundingClientRect().width - this.arrowWidth
    let rollContainerWidth = this.$refs['rollContainer'].getBoundingClientRect().width
    let tabItem = document.querySelectorAll('.j-nav-first-cate')

    this.itemsOffsetX.splice(0)

    if (!gbCommonInfo.GB_cssRight) {
      this.minTransformX = outerWidth - rollContainerWidth
    } else {
      this.minTransformX = rollContainerWidth - outerWidth
    }


    if (
      (!gbCommonInfo.GB_cssRight && this.minTransformX < -this.arrowWidth) ||
      (gbCommonInfo.GB_cssRight && this.minTransformX > this.arrowWidth)
    ) {
      this.showArrow = true
      for (var i = 0; i < tabItem.length; i++) {
        if (!gbCommonInfo.GB_cssRight) {
          if (-tabItem[i].offsetLeft > this.minTransformX) {
            this.itemsOffsetX.push(tabItem[i].offsetLeft)
          }
        } else {
          if (-(tabItem[i].offsetLeft + tabItem[i].offsetWidth - this.$refs['rollContainer'].offsetWidth) < this.minTransformX) {
            this.itemsOffsetX.push(tabItem[i].offsetLeft + tabItem[i].offsetWidth - this.$refs['rollContainer'].offsetWidth)
          }
        }
      }
      if (!gbCommonInfo.GB_cssRight) {
        this.itemsOffsetX.push(tabItem[this.itemsOffsetX.length].offsetLeft)
      } else {
        this.itemsOffsetX.push(tabItem[this.itemsOffsetX.length].offsetLeft + tabItem[this.itemsOffsetX.length].offsetWidth - this.$refs['rollContainer'].offsetWidth)
      }

      if (doDefaultScroll) {
        this.setDefaultIndex(this.defaultIndex)
      }
    }
  },
  setDefaultIndex(index = 0) {
    let tabItem = document.querySelectorAll('.j-nav-first-cate')
    let activePos = 0

    this.columnOffsetIndex = range(index, 0, this.itemsOffsetX.length - 1)

    if (!gbCommonInfo.GB_cssRight) {
      activePos = tabItem[this.defaultIndex].offsetLeft
    } else {
      activePos = tabItem[this.defaultIndex].offsetLeft + tabItem[this.defaultIndex].offsetWidth - this.$refs['rollContainer'].offsetWidth
    }

    if (!gbCommonInfo.GB_cssRight) {
      this.adsorb(this.columnOffsetIndex, -activePos - (this.$refs['activeItem'].getBoundingClientRect().width / 2) + ((this.$refs['rollOuter'].getBoundingClientRect().width - this.arrowWidth) / 2), 0)
    } else {
      this.adsorb(this.columnOffsetIndex, -activePos + (this.$refs['activeItem'].getBoundingClientRect().width / 2) - ((this.$refs['rollOuter'].getBoundingClientRect().width - this.arrowWidth) / 2), 0)
    }
  },
  addWindowResizeListener() {
    var resizeTimeout

    window.addEventListener('resize', () => {
      if ( !resizeTimeout ) {
        resizeTimeout = setTimeout(() => {
          resizeTimeout = null
          this.setDefault()
        }, 1000)
      }
    }, false)
  },
  adsorb(index = 0, offset = 0, duration = 200) {
    if (index < 0 || index > this.itemsOffsetX.length - 1) {
      console.warn('firstcate scroll index is invalid', this.columnOffsetIndex, this.itemsOffsetX.length)
      return
    }
    if (!gbCommonInfo.GB_cssRight) {
      this.columnOffsetX = range( offset, this.minTransformX, this.maxTransformX )
    } else {
      this.columnOffsetX = range( offset, this.maxTransformX, this.minTransformX )
    }
    this.setWrapperStyle(this.columnOffsetX, duration)
  },
  setWrapperStyle (transformX, duration = 0) {
    if (!gbCommonInfo.GB_cssRight) {
      transformX = range(transformX, this.minTransformX - this.elasticity, this.maxTransformX + this.elasticity)
    } else {
      transformX = range(transformX, this.maxTransformX - this.elasticity, this.minTransformX + this.elasticity)
    }

    this.wrapperStyle = {
      x: transformX,
      transition: `transform ${duration}ms`,
      transform: `translate3d(${transformX}px, 0px, 0px)`
    }
  },
  prev() {
    this.columnOffsetIndex = range( this.columnOffsetIndex - 1, 0, this.itemsOffsetX.length - 1)
    this.adsorb(this.columnOffsetIndex, -this.itemsOffsetX[this.columnOffsetIndex])
  },
  next() {
    this.columnOffsetIndex = range( this.columnOffsetIndex + 1, 0, this.itemsOffsetX.length - 1)
    this.adsorb(this.columnOffsetIndex, -this.itemsOffsetX[this.columnOffsetIndex])
  }
}

if (document.querySelector('.j-cm-first-cate-ctn')) {
  firstCateScroll.getDom()
  if (!(navigator.userAgent.indexOf('MSIE') > 0) && document.fonts && document.fonts.ready) {
    document.fonts.ready.then(function() {
      firstCateScroll.mounted()
    })
  } else {
    onWindowLoad(()=> {
      firstCateScroll.mounted()
    })
  }
  document.querySelector('.j-cm-header-arrow-l').addEventListener(
    'click',
    firstCateScroll.prev.bind(firstCateScroll)
  )
  document.querySelector('.j-cm-header-arrow-r').addEventListener(
    'click',
    firstCateScroll.next.bind(firstCateScroll)
  )
}
