/* eslint-disable no-mixed-spaces-and-tabs */
import './render'
const { lang, IS_RW, SiteUID, MUL_SITE_LANG, IS_MUL_SITE, NODE_SERVER_ENV, SUPPORT_LANGS } = gbCommonInfo
import { setCookie, windowCacheDeleteCb, parseQueryString, stringifyQueryString, getQueryString, setLocalStorage } from '@shein/common-function'
// import schttp from 'public/src/services/schttp'
import { reloadByNoCache, onWindowLoad } from 'public/src/pages/common/utils/index.js'
import UserInfoManager from 'public/src/services/UserInfoManager/UserInfoManager.js'
import SfDocument from './sfDocument'

// const expTime = 3600 * 24 * 7
//特殊的多语言cookie key（历史原因）
// const cookieKeys = {
//   en: 'SHEIN_WWW_MULTIPLE_LANG',
//   il: 'SHEIN_W_DOUBLE_LANG',
//   eur: 'eur_four_lang',
// }
//cookie在二级域名下的站点
const selfDomain = ['se', 'eur', 'us']

let isDomain = true
//多语言cookie的key

for( let key in MUL_SITE_LANG ){
  if( MUL_SITE_LANG[key].langInfo && MUL_SITE_LANG[key].langInfo[lang] ){
    isDomain = !selfDomain.includes(key)
  }
}

//开发环境本地调试
// eslint-disable-next-line no-unused-vars
NODE_SERVER_ENV == 'localhost' && (isDomain = false)


onWindowLoad(() => {
  requestAnimationFrame(function() {
    // 切换货币
    new SfDocument(document).on('click', '.j-change-currency', async function(event) {
      event.preventDefault()
      event.stopPropagation()
      var currency = new SfDocument(this).data('currency')
            

      await UserInfoManager.set({ key: 'currency', value: currency })
      // schttp({
      //     url: '/api/common/currency/update',
      //     params: { currency }
      // }).then(() => {

      const expireTime = 3600 * 24 * 30 // 30 day
      //setCookie({key: 'currency', value: currency, end: expireTime})
      setLocalStorage({ key: 'manualChangeCurrency', value: 1, expire: expireTime * 1000 }) // 手动切换

      windowCacheDeleteCb({
        key: 'dynamicData',
      })

      if((!currency || currency == 'undefined') && typeof(ErrorJs) != 'undefined') {
        var _data = {
          url: location.href
        }
        const { SA_REPORT_URL } = gbCommonInfo.REPORT_URL
        // eslint-disable-next-line no-undef
        ErrorJs.sendError('error', SA_REPORT_URL + '/unusual', { currency_error_msg: _data })
      }

      //如果用户的当前也有currency参数，切换货币后去掉currency参数，以用户选择的货币为准
      const querys = parseQueryString(location.search)
      delete querys.currency
      querys._t = Date.now()

      location.href =
                        location.origin +
                        location.pathname + '?' + stringifyQueryString({ queryObj: querys })
      //})
    })

    function toInternational(url) {
      const site = SiteUID.replace(/^(rw)/, '')
      // eslint-disable-next-line @shein-aidc/common/notDirectUseCookie
      setCookie({ key: 'jump_to_' + site, value: '0', end: 3600 * 24, curMainDomain: true }) //1天内不再跳转。
      if (url.indexOf('?') > -1) {
        window.location.href = url += `is_manual_change_site=0&is_from_origin_btn=0&ref=${site}&ret=www&from_country=${site}`
      } else {
        window.location.href = url += `?is_manual_change_site=0&is_from_origin_btn=0&ref=${site}&ret=www&from_country=${site}`
      }
    }

    // 切回国际站
    new SfDocument('#txt_lang').click(function() {
      toInternational(IS_RW ? 'https://www.romwe.com' : 'https://www.shein.com')
    })
    // 双语切换
    new SfDocument('.j-change-language').click(async function() {
      var language = new SfDocument(this).data('lang')

      sa('send', {
        activity_name: 'click_change_site_language',
        activity_param: {
          language: language
        }
      }, { beacon: 1 })

      await UserInfoManager.set({ key: 'language', value: language })

      // let cdn_key = parseQueryString(getCookie('cdn_key'))
      // cdn_key[`${SiteUID}lang`] = language

      //手动设置语言与链接设定语言冲突时，按手动设置优先，将lang替换为0
      var urlLang = getQueryString({ key: 'lang' })
      if( urlLang && urlLang != '0' && IS_MUL_SITE ){
        //window.location.href = window.location.href.replace('lang=' + urlLang, 'lang=0')
        reloadByNoCache({ langZero: true })
      } else {
        reloadByNoCache()
      }
    });
    (function() {
    // eslint-disable-next-line no-mixed-spaces-and-tabs
        	let lang = getQueryString({ key: 'lang' })
      lang = lang && lang.indexOf('asiaja') > -1 ? 'jp' : lang

      //有没可能落地页是cdn没回源，且页面url带了query lang，没法触发cookie
      //当普通请求此时query没带lang，不会触发请求set-cookie，所以才在下面设置了set-cookie
            

        	if( lang && lang != '0' && SUPPORT_LANGS.length ){
        UserInfoManager.set({ key: 'language', value: lang }).then(() => {
          reloadByNoCache({ langZero: true })
        })
        	}
    })()
  })
})
