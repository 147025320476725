// import country_str from '../../../../../views/common/header_country.html'
import header_dropdown from '../../../../../views/common/header_dropdown.html'
import currencies_str from '../../../../../views/common/header_currencies.html'
import schttp from 'public/src/services/schttp'
import { getQueryString, /* getLocalStorage */ } from '@shein/common-function'
import { /* renderCategoryVue as CategoryVueRender, renderOptimizeCategory,*/ renderOptimizeCategoryNew } from './category'
// 神策埋点（导航栏有需要上报）

// const cccNavIsOptimize = window.cccNavIsOptimize
// const isNewCccx = window.isNewCccx
import SfDocument from './sfDocument'


const {
  SiteUID,
  /* WEB_CLIENT,*/ lang, language, IS_RW, MUL_SITE_LANG, IS_MUL_SITE,
} = gbCommonInfo

// function getDefaultChannelId() {
//   // shein 根路由取 all 频道数据
//   const isShein = WEB_CLIENT === 'shein'
//   return isShein ? 10 : 2
// }

// const active_cate_channel_type = getLocalStorage('cate_channel_type') || getDefaultChannelId()

function getCurrenciesData () {
  const { currencies, currency } = gbCommonInfo
  let data = {}
  try {
    data = JSON.parse(localStorage.getItem('currencyData')) || currencies
  } catch (e) {
    data = currencies
  }
  const currencyList = [] // 货币列表
  let defaultCurrency = {} // 默认货币
  let showCurrency = {} // 展示的货币
  Object.keys(data).forEach((k) => {
    if(data[k].default == 1) {
      defaultCurrency = data[k]
    } else {
      currencyList.push(data[k])
    }
  })
  // 将默认货币置顶在第一位
  currencyList.unshift(defaultCurrency)

  showCurrency = data[currency] || defaultCurrency

  return { defaultCurrency, currencyList, showCurrency }
}

const { currencyList, showCurrency } = getCurrenciesData()

// eslint-disable-next-line no-undef
var dropdown_template_fn = ejs.compile(header_dropdown)
// var country_template_fn = ejs.compile(country_str) // TODO: [x] Vue 3 升级：升级过程中发现未使用，注释掉
// eslint-disable-next-line no-undef
var currencies_template_fn = ejs.compile(currencies_str)

// 渲染货币语言切换组件
new SfDocument('.j-header-location-dropdown').append(dropdown_template_fn({
  showCurrency,
  lang,
  language,
  IS_RW,
  SiteUID,
  MUL_SITE_LANG,
  IS_MUL_SITE,
}))

// function render_category(nav_cate) {
//   Array.isArray(nav_cate) &&
//         nav_cate.forEach((cate, cate_index) => {
//           if (cate && active_cate_channel_type == cate.channel_type) {
//             // handleCate(cate, cate_index)
//             CategoryVueRender(cate, cate_index)
//           }
//         })
// }

function fetchCategoriesData(/*activeChannelType*/) {
  return new Promise(resolve => {
    // 需要导航的时候落地页才需要发起请求
    // if (typeof window !== 'undefined' && !window.headerNavHtml) return resolve([]) 
    if (!window.hasOwnProperty('cccNavData')) return resolve([])
    if (typeof window !== 'undefined' && window.cccNavData && window.cccNavData instanceof Array) {
      resolve(window.cccNavData)
      // if (cccNavIsOptimize) return resolve(window.cccNavData) // 屏效导航只会返回一级类目
      // const result = window.cccNavData.find(_ => _.channelType == activeChannelType)
      // // 如果对应的频道没有二级导航
      // // 客户端需要重新发起一个请求兜底
      // if (result?.child?.length) {
      //   return resolve(window.cccNavData)
      // } else {
      //   return schttp({ url: '/get_categories', }).then(resolve)
      // }
    }
    return resolve([])
  })
}


// 判断是否为 CCC 预览导航功能
// const content_id = getQueryString({ key: 'content_id' })
// const scene_key = getQueryString({ key: 'scene_key' })
// const contentId = getQueryString({ key: 'contentId' })
// const sceneKey = getQueryString({ key: 'sceneKey' })
// const channelType = getQueryString({ key: 'channelType' })
// if ( (content_id && scene_key && scene_key.includes('navigation') && !cccNavIsOptimize) || (contentId && channelType && sceneKey && sceneKey.includes('navigation') && !cccNavIsOptimize) ) {

//   // scParams.data.user_group_id = '0'

//   let data = {
//     product: WEB_CLIENT,
//     site: lang,
//     lang,
//     terminal: 'pc',
//   }
//   if (channelType) { // 新类目导航
//     data.contentId = contentId
//     data.channelType = channelType
//   } else {
//     data.contents_id = content_id
//   }

//   schttp({
//     url: '/api/ccc/navigation_ccc_preview/query',
//     params: data
//   }).then(res => {
//     if (res && res.length) {
//       res.forEach((cate, cate_index) => {
//         // handleCate(cate, cate_index)
//         CategoryVueRender(cate, cate_index)
//       })
//     }
//   })
// } else {
//   if (cccNavIsOptimize) { // 是否使用屏效导航
//     // 区分是否为为接入cccx 的数据
//     if(isNewCccx) {
//       fetchCategoriesData().then(renderOptimizeCategoryNew)
//     } else {
//       fetchCategoriesData().then(renderOptimizeCategory)
//     }
//   } else {
//     fetchCategoriesData(active_cate_channel_type).then(render_category)
//   }
// }
fetchCategoriesData().then(renderOptimizeCategoryNew)

// 判断是否为 CCC 预览导航功能
const content_id = getQueryString({ key: 'content_id' })
const scene_key = getQueryString({ key: 'scene_key' })
if ( content_id && scene_key && scene_key == 'skin' ) {
  schttp({
    url: `/api/ccc/getCccSkinPreview/query?content_id=${content_id}&scene_key=${scene_key}`,
  }).then(json => {
    if (json?.data?.content?.content?.props?.items?.[0]?.topLogoImgSrc) {
      let imgNode = document.createElement('img')
      imgNode.setAttribute('src', json.data.content.content.props.items[0].topLogoImgSrc)
      document.querySelector('.j-ccc-skinlogo').appendChild(imgNode)
    }
  })
}


new SfDocument('.j-header-currencies-ctn').append(
  currencies_template_fn({
    currencyList
  })
)
