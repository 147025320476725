import { abtservice } from 'public/src/services/abt'
import schttp from 'public/src/services/schttp'
import { getUserInfo } from 'public/src/pages/common/userInfo'
import { isLogin } from 'public/src/pages/common/utils/index.js'

const { SiteUID } = gbCommonInfo

!(async function () {
    try {
      const { Movepoints } = await abtservice.getUserAbtResult({ posKeys: 'Movepoints' })
      const { param } = Movepoints
      const user = await getUserInfo()
      if (isLogin() && param == 'type=B') {
        const member_id = user?.member_id || ''
        const isEverLogined = localStorage.getItem(`${member_id}_${SiteUID}_isEverLogined`) || ''
        let pointMigrateFlag = localStorage.getItem(`${member_id}_${SiteUID}_pointMigrateFlag`) ? JSON.parse(localStorage.getItem(`${member_id}_${SiteUID}_pointMigrateFlag`)) : {}
        let nowTime = new Date().getTime()
        let flagSetTime = pointMigrateFlag ? pointMigrateFlag.time : ''
        if (!isEverLogined) {
          // 首次登录新开站点进行积分迁移
          localStorage.setItem(`${member_id}_${SiteUID}_isEverLogined`, true)
          schttp({
            url: `/api/user/points/autoMigrate/update`,
          }).then(res => {
              if (res.code == 0 && res.info) {
                if (res.info?.result != 1) {
                  // 迁移失败，本地新增标志位记录调用失败，下次重启发起请求
                  localStorage.setItem(`${member_id}_${SiteUID}_pointMigrateFlag`, JSON.stringify({ status: 'error', time: new Date().getTime() }))
                } else {
                  localStorage.setItem(`${member_id}_${SiteUID}_pointMigrateFlag`, JSON.stringify({ status: 'success', time: new Date().getTime() }))
                }
              } else {
                localStorage.setItem(`${member_id}_${SiteUID}_pointMigrateFlag`, JSON.stringify({ status: 'error', time: new Date().getTime() }))
              }
            }).catch(() => {
              localStorage.setItem(`${member_id}_${SiteUID}_pointMigrateFlag`, JSON.stringify({ status: 'error', time: new Date().getTime() }))
            })
        }

        if (['/', '/user/index', '/user/points', '/checkout'].includes(location?.pathname) &&
          pointMigrateFlag?.status == 'error' &&
          flagSetTime && (nowTime - flagSetTime > 600000)) {
          // 首页、个人中心、积分页、下单页接收到积分迁移失败标识符，再次发送迁移请求
          schttp({
            url: `/api/user/points/autoMigrate/update`,
          }).then(res => {
              if (res.code == 0 && res.info) {
                if (res.info?.result != 1) {
                  // 迁移失败，本地新增标志位记录调用失败，下次重新发起请求
                  localStorage.setItem(`${member_id}_${SiteUID}_pointMigrateFlag`, JSON.stringify({ status: 'error', time: new Date().getTime() }))
                } else {
                  localStorage.setItem(`${member_id}_${SiteUID}_pointMigrateFlag`, JSON.stringify({ status: 'success', time: new Date().getTime() }))
                }
              } else {
                localStorage.setItem(`${member_id}_${SiteUID}_pointMigrateFlag`, JSON.stringify({ status: 'error', time: new Date().getTime() }))
              }
            }).catch(() => {
              localStorage.setItem(`${member_id}_${SiteUID}_pointMigrateFlag`, JSON.stringify({ status: 'error', time: new Date().getTime() }))
            })
        }
      }
    } catch (e) {
      console.log(e)
    }
}())
