/**
 * 普通导航
 * @param {*} data 
 * @param {*} index 
 * @returns 
 */
export const renderCategoryVue = (data, index) => {
  import(/* webpackChunkName: "CategoryContainer" */ './components/loadCategoryContainer').then(({ default: CategoryContainer }) => {
    new CategoryContainer().init(data, index)
  })
}

// /**
//  * 屏效导航
//  */
// export const renderOptimizeCategory = async (navCates) => {
//   import(/* webpackChunkName: "OptimizeContainer" */ './optimize/loadOptimizeContainer').then(({ default: OptimizeContainer }) => {
//     new OptimizeContainer().init(navCates)
//   })
// }

/**
 * 接入新ccc 屏效导航
 */
export const renderOptimizeCategoryNew = async (navCates) => {
  import(/* webpackChunkName: "OptimizeCCCContainer" */ './optimizeCcc/loadOptimizeCccContainer').then(({ default: OptimizeCCCContainer }) => {
    new OptimizeCCCContainer().init(navCates)
  })
}


