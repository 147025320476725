const { isActivity } = gbCommonInfo
import { throttle, setLocalStorage } from '@shein/common-function'
import { animationDriver } from '@shein-aidc/utils-animation-laptop'
import SfDocument from './sfDocument'
import { onWindowLoad } from 'public/src/pages/common/utils/index.js'
// import { loadUserDropDown } from 'public/src/pages/common/userDropdown/loadUserDropDown.js'

/**
 * 匹配当前pathname
 * @param {*} value 
 * @returns Boolean
 */
const locationPathMatching = (pageList = []) => {
  const { pathname } = location
  return pageList.some((pagePath) => {
    return pathname.includes(pagePath)
  })
}

(async () => {    
    let $window = document.documentElement
    let $allheader = new SfDocument('.j-c-header1')
    let beforeScrollTop = $window.scrollTop
    let afterScrollTop
    let beforeStatus

    // 是否启用向上滚动还原头部导航
    let scrollUpResetClass = true

    // ----------------- srcoll start -----------------
    // 滚动处理
    const pageScrollHandel = function(event, config) {
      // 设计师列表页不需要头部滚动
      if (location.pathname === '/designer_list') return
      if (isActivity && window.dispatchByJs) {
        window.dispatchByJs = false
        return
      }
      
      if (window.__noNeedNavScoll) return
      let currStatus
      let scrollTop = (afterScrollTop = $window.scrollTop)

      // 判断这次是向上还是向下
      let scrollDelta = config && config.scrollDelta || afterScrollTop - beforeScrollTop
      beforeScrollTop = afterScrollTop

      let $allheader_fixer = new SfDocument('.j-topbanner-fixer, .j-header-fixer')
      let $allheader_height = $allheader.height()
      if (scrollTop <= $allheader_height) {
        currStatus = 1
      } else if (scrollDelta > 0) {
        currStatus = 2
      } else if (scrollDelta < 0) {
        currStatus = 3
      }

      // 相同状态不重复触发
      if (beforeStatus == currStatus) {
        return
      }

      beforeStatus = currStatus
      let $easter_bunny = new SfDocument('.j-ebunny-game__top')
      let $order_review_category = new SfDocument('.j-order-review-category')

      if (scrollTop <= $allheader_height) {
        // 向上滚动接近最顶
        window.showAllHeader = true
        $allheader_fixer.removeClass('topscroll-hide')
        $allheader_fixer.css('transform', 'translateY(0)')
        if ($easter_bunny.length > 0) {
          $easter_bunny.css('top', $allheader_height)
        }
        if ($order_review_category.length > 0) {
          $order_review_category.removeClass('order-review-category_pos-fixed order-review-category_pos-fixed0')
          $order_review_category.css('top', '0')
        }
      } else if (scrollDelta > 0) { // 向下滚动

        window.showAllHeader = false
        $allheader_fixer.addClass('topscroll-hide')
        $allheader_fixer.css('transform', 'translateY(-' + $allheader_height + 'px)')
        if ($easter_bunny.length > 0) {
          $easter_bunny.css('top', 0)
        }
        if ($order_review_category.length > 0) {
          $order_review_category.addClass('order-review-category_pos-fixed order-review-category_pos-fixed0')
          $order_review_category.css('top', '0')
        }
      } else if (scrollDelta < 0 && scrollUpResetClass) { // 向上滚动

        window.showAllHeader = true
        $allheader_fixer.removeClass('topscroll-hide')
        $allheader_fixer.css('transform', 'translateY(0)')
        if ($easter_bunny.length > 0) {
          $easter_bunny.css('top', $allheader_height)
        }
        if ($order_review_category.length > 0) {
          $order_review_category.addClass('order-review-category_pos-fixed')
          $order_review_category.removeClass('order-review-category_pos-fixed0')
          $order_review_category.css('top', $allheader_height)
        }
      }
    }

    // 是否在店铺页面 path: /store/home
    // 店铺页有一个粘性定位header bar 所以不需要头部的向上滚动显示
    // 则向上滚动除非走crollTop <= $allheader_height判断，其他不需要向上滚动就显示公共头部
    const noHeaderPathPages = [
      '/store/home',
      '/global/marketing'
    ]
    if (locationPathMatching(noHeaderPathPages)) {
      try {
        scrollUpResetClass = false
        // 模仿一个向下滚动初始触发
        beforeScrollTop = 10
        pageScrollHandel()
      } catch (e) {
        console.error(e)
      }
    }

    const scrollFun = throttle({
      func: pageScrollHandel,
      wait: 200,
      options: {
        trailing: true
      }
    })
    window.addEventListener('scroll', scrollFun)

    window.pageScrollHandel = pageScrollHandel
    // ----------------- srcoll end -----------------

    // ----------------- resize start -----------------
    const pageResizeHandel = function() {
      let widthWithoutScrollbar = document.documentElement.clientWidth
      widthWithoutScrollbar = widthWithoutScrollbar - 1 // 解决页面稍宽出现底部滚动条
      $allheader.css('width',  widthWithoutScrollbar + 'px')
    }
    // 监听屏幕变化，重置头部宽度
    const resizeFun = throttle({
      func: pageResizeHandel,
      wait: 200,
      options: {
        trailing: true
      }
    })

    window.addEventListener('resize', resizeFun)
   
    // 页面加载获取宽度，重置宽度
    onWindowLoad(pageResizeHandel)
    // ----------------- resize end -----------------
})()

new SfDocument(document)
  .on('mouseleave', '.j-global-dd-wrap', function() {
    new SfDocument('.j-global-s-drop').hide()
    new SfDocument('.j-global-dd-wrap .header-right-global').removeClass('active')
    new SfDocument('.j-header-location-dropdown').removeClass('she-show')
  })
  .on('mouseenter', '.j-global-dd-wrap', function() {
    sa('send', {
      activity_name: 'click_bublle_language'
    })
    window.GB_SWITCH_LANGUAGE_MODAL && window.GB_SWITCH_LANGUAGE_MODAL.close()
    new SfDocument('.j-global-dd-wrap .header-right-global').addClass('active')
    new SfDocument('.j-header-location-dropdown').addClass('she-show')
  })
  .on('focus', '.j-header-username-icon', function() {
    new SfDocument('.j-user-dropdown').addClass('focus-user-icon1')
    new SfDocument('.j-user-dropdown').removeClass('focus-user-icon')
  })
  .on('keydown', '.j-header-username-icon', function (event) {
    const j_user_dropdown = new SfDocument('.j-user-dropdown')
    if (j_user_dropdown.hasClass('focus-user-icon1') && event.keyCode == 13) {
      event.preventDefault()
      j_user_dropdown.addClass('focus-user-icon').find('a').eq(0).trigger('focus')
    }
  })
  .on('keydown', '.j-user-dropdown a:last', function(e) {
    if (e.keyCode == 9 && !e.shiftKey) {
      e.preventDefault()
      new SfDocument('.j-user-dropdown a:first-child').trigger('focus')
    }
  })
  .on('focus', '.j-header-right-global', function() {
    new SfDocument('.j-header-location-dropdown').addClass('focus-user-icon1')
    new SfDocument('.j-header-location-dropdown').removeClass('she-show')
  })
  .on('click', '.j-header-right-global', function () {
    new SfDocument('.j-header-location-dropdown').addClass('she-show').find('.j-header-currency').trigger('focus')
  })
  .on('keydown', '.j-header-location-dropdown', function(event) {
    if (event.keyCode == 27) {
      new SfDocument('.j-header-right-global').trigger('focus')
      new SfDocument('.j-header-location-dropdown').removeClass('she-show')
    }
  })
  .on('keydown', '.j-header-location-dropdown a:last', function(e) {
    if (e.keyCode == 9 && !e.shiftKey) {
      e.preventDefault()
      new SfDocument('.j-header-currency').trigger('focus')
    }
  })
  .on('focus', '.j-header-customer', function() {
    new SfDocument('.j-header-robot-service').addClass('focus-user-icon')
  })
  .on('mouseenter', '.j-header-customer', function () {
    new SfDocument('.j-header-robot-service').addClass('focus-user-icon')
  })
  .on('mouseleave', '.j-header-customer', function () {
    new SfDocument('.j-header-robot-service').removeClass('focus-user-icon')
  })
  .on('click', '.j-header-customer', function () {
    new SfDocument('.j-header-robot-service').removeClass('focus-user-icon')
  })
  .on('blur', '.j-header-robot-service a:last-child,.header-help', function() {
    new SfDocument('.j-header-robot-service').removeClass('focus-user-icon')
  })
  .on('click', '.j-nav-first-cate', function() {
    var $this = new SfDocument(this)
    var channel_type = $this.data('channel_type')
    if ($this.hasClass('hoverdefalut')) {
      new SfDocument('.j-nav-first-cate').removeClass('cate-active')
      $this.addClass('cate-active')
    }
    setLocalStorage({
      key: 'cate_channel_type',
      value: channel_type,
      expire: 1000 * 2600 * 24 * 30
    })
  })
  .on('keydown', '.j-global-s-drop-ctn', function(e) {
    if (e.keyCode == 13) {
      currencyListClick()
      setTimeout(() => {
        new SfDocument('.j-global-s-drop li:first-child > span').focus()
      }, 200)
    }
    if (e.keyCode == 27) {
      e.stopPropagation()
      new SfDocument('.j-global-s-drop').hide()
      new SfDocument('.j-currency-list').focus()
    }
  })
  .on('keyup', '.j-change-currency', function(e) {
    if (e.keyCode == 13) {
      new SfDocument(this).click()
    }
  })
  .on('blur', '.j-change-currency:last', function() {
    new SfDocument('.j-global-s-drop').hide()
  })
  .on('focus', '.j-currency-list', function() {
    new SfDocument('.j-global-s-drop').hide()
  })
  .on('mousedown', '.j-header-height', function() {
    new SfDocument('.j-header-height').addClass('no-focus')
  })
  .on('keydown', '.j-header-height', function() {
    new SfDocument('.j-header-height').removeClass('no-focus')
  })
  .on('click', '.j-global-s-drop-ctn', function(event) {
    event.preventDefault()
    event.stopPropagation()
    currencyListClick()
  })
  .click(function(event) {    
    const jdrop1 = new SfDocument('.j-global-s-drop-ctn-1')
    const jdrop2 = new SfDocument('.j-global-s-drop-ctn-2')
    if (!(new SfDocument(event.target).hasClass('j-global-s-drop-ctn') || 
    (jdrop1.length > 0 && SfDocument.contains(jdrop1.element, event.target)) ||
    (jdrop2.length > 0 && SfDocument.contains(jdrop2.element, event.target))) ) {
      let domNode = document.querySelector('.j-global-s-drop')
      if (domNode && domNode.style.display == 'block') {
        new SfDocument('.j-global-s-drop').hide()
      }
    }
  })

function currencyListClick () {
  sa('send', {
    activity_name: 'click_popup_country_change'
  })
  // this一直是undefined呀！
  // new SfDocument(this)
  //   .siblings('.j-global-s-drop-ctn')
  //   .children('.j-global-s-drop')
  //   .hide()

  if (document.querySelector('.j-global-s-drop').style.display == 'block') {
    return
  } else {
    document.querySelector('.j-global-s-drop').style.display = 'block'
    animationDriver('.j-global-s-drop', 'sui-animation__selectmenu-enter').then(() => {
      new SfDocument(this)
        .children('.j-global-s-drop')
        .show()
    })
  }
}

if (navigator.userAgent.toLowerCase().match(/phone|pad|pod|iphone|ipod|ios|ipad|android/)) {
  new SfDocument(document)
    .on('touchstart', '.j-header-mask', function() {
      var self = this
      setTimeout(function() {
        new SfDocument(self).hide()
      }, 400)
    })
}
