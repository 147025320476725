/**
 * 站外跳转缺失resource_content兜底
 */
export const resourceContentBackup = () => {
  const path = location.pathname
  let matchObj
  matchObj = path.match(/\/daily-new.html$/)
  if (matchObj) return 'Special_dailyNew'

  matchObj = path.match(/\/.+-c-(\d+)\.html$/)
  if (matchObj) return `real_${matchObj[1]}`

  matchObj = path.match(/\/((\w+)\/)?.+-sc-([A-Za-z0-9]+)\.html$/)
  if (matchObj) return `itemPicking_${matchObj[3]}`

  matchObj = path.match(/\/(?:promo-discount|[a-z]+-picks|romwe-[a-z]+)(-([a-zA-Z0-9\+]+))*\.html$/)
  if (matchObj) return 'Special_SheinPicks'

  matchObj = path.match(/\/flash-sale.html$/)
  if (matchObj) return 'Special_flashSale'

  return ''
}
