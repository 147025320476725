import schttp from 'public/src/services/schttp'
import UserInfoManager from 'public/src/services/UserInfoManager/UserInfoManager.js'

const { SiteUID = '', PERSONALIZED_SWITCH = {} } = typeof window !== 'undefined' ? gbCommonInfo : {}
const showInfo = PERSONALIZED_SWITCH?.[SiteUID] ?? 1

// ugid/init
const key = 'WEB_UGID_INIT'
const timing = localStorage.getItem(key)
let expired = false // 是否已经过期
// 24h 过期
if (timing) expired = new Date().getTime() - timing > 24 * 60 * 60 * 1000
// 如果已经过期或者没有值，则请求
if (expired || !timing) {
  localStorage.removeItem(key)
  schttp({
    method: 'POST',
    url: '/api/common/ugidInit'
  }).then(res => {
    if (res.code == 0) {
      localStorage.setItem(key, new Date().getTime())
    }
  })
}

// 添加埋点拦截器
// async 不能删掉 ！！！一定要 promise 返回，否则会有大问题！！！
window.analysisBeforeInterceptor.use(async function (result) {
  // result == { data: {} }
  try {
    if (result.data) {
      const [currency, country, memberId, ugid] = await Promise.all([
        UserInfoManager.get({ key: 'currency' }),
        UserInfoManager.get({ key: 'Country' }),
        UserInfoManager.get({ key: 'memberId' }),
        UserInfoManager.get({ key: 'ugid' }),
      ])
      
      Object.assign(result.data, {
        member_id: memberId,
        login: Number(Boolean(memberId)),
        money_type: currency || '',
        device_country: country && country.countryAbbr || '',
        ugid: ugid,
      })

      result.data.showInfo = showInfo
    }
    return result
  } catch (e) {
    return result
  }
})
