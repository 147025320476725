class SfDocument {
    $els
    #firstElement // 私有变量来存储第一个元素

    // 私有数据操作方法
    #data(key, value, operation) {
      if (this.$els.length === 0) return undefined
      if (operation === 'get') {
        return this.#firstElement.dataset[key]
      }
      this.$els.forEach(el => {
        if (operation === 'set') {
          el.dataset[key] = value
        } else if (operation === 'remove') {
          delete el.dataset[key]
        }
      })
      return this
    }

    #transformJQ2CSS(selector) {
      // 转换:first为:first-child
      selector = selector.replace(/:first\b(?!-child)/g, ':first-child')
      // 转换:last为:last-child
      selector = selector.replace(/:last\b(?!-child)/g, ':last-child')
      // 转换:eq(index)为:nth-child(index+1)
      selector = selector.replace(/:eq\((\d+)\)/g, function(match, index) {
        return `:nth-child(${parseInt(index, 10) + 1})`
      })
  
      return selector
    }
  
    constructor(selector) {
      let elements = [] // 初始化要选取的DOM元素列表
      if (typeof selector === 'string') {
        selector = this.#transformJQ2CSS(selector)
        elements = document.querySelectorAll(selector)
      } else if (selector instanceof Element || selector === document) {
        // 如果传入的是一个DOM元素或document对象，将其置入数组中
        elements = [selector]
      } else if (selector instanceof NodeList || Array.isArray(selector)) {
        // 如果传入的是NodeList或数组，则直接赋值
        elements = selector
      } else {
        throw new TypeError('Expected a string selector, DOM element, NodeList, or array of elements')
      }
      this.$els = elements // 保存筛选后的元素数组
      this.#firstElement = elements[0] // 将第一个元素保存为私有变量
    }
  
    get length() {
      return this.$els.length
    }
    get element() {
      return this.#firstElement
    }
  
    height() {
      return this.#firstElement ? this.#firstElement.clientHeight : 0
    }
  
    removeClass(classNames) {
      if (this.$els.length === 0 || !classNames) return this
      const classes = classNames.split(' ')
      this.$els.forEach(el => {
        el.classList.remove(...classes)
      })
      return this
    }
  
    addClass(classNames) {
      if (!classNames || this.$els.length === 0) return this
      const classes = classNames.split(' ')
      this.$els.forEach(el => {
        el.classList.add(...classes)
      })
      return this
    }
  
    css(cssKey, cssValue) {
      if (this.$els.length === 0) return this
      if (typeof cssKey === 'object') {
        Object.keys(cssKey).forEach(key => {
          this.$els.forEach(el => {
            el.style[key] = cssKey[key]
          })
        })
      } else {
        this.$els.forEach(el => {
          el.style[cssKey] = cssValue
        })
      }
      return this
    }
  
    hide() {
      this.css('display', 'none')
      return this
    }
  
    show() {
      this.css('display', '')
      return this
    }
  
    find(selector) {
      if (this.$els.length === 0 || !selector) return new SfDocument([])
      const foundElements = []
      this.$els.forEach(el => {
        const descendants = el.querySelectorAll(selector)
        foundElements.push(...descendants)
      })
      return new SfDocument(foundElements)
    }
  
    eq(index) {
      return new SfDocument(index >= 0 && index < this.$els.length ? [this.$els[index]] : [])
    }
  
    trigger(eventName) {
      if (this.$els.length === 0) return this
      this.$els.forEach(el => {
        if(['focus', 'blur'].includes(eventName)) {
          el[eventName]()
        } else {
          el.dispatchEvent(new Event(eventName))
        }
        
      })
     
      return this
    }
  
    data(key, value) {
      if (value === undefined) {
        return this.#data(key, undefined, 'get') // 读取数据
      } else {
        return this.#data(key, value, 'set') // 存储数据
      }
    }
  
    removeData(key) {
      return this.#data(key, undefined, 'remove') // 删除数据
    }
  
    hasClass(className) {
      if (this.$els.length === 0) return false
      return Array.from(this.$els).some(el => el.classList.contains(className))
    }
  
    focus() {
      if (this.#firstElement) {
        this.#firstElement.focus()
      }
      return this
    }

    click(callback) {
      if (callback && typeof callback === 'function') {
        // 如果提供了回调函数，就添加点击事件监听
        this.$els.forEach(el => {
          el.addEventListener('click', callback)
        })
      } else {
        // 如果没有提供回调函数，则触发点击事件
        this.$els.forEach(el => {
          if (document.createEvent) {
            // 对于支持createEvent的浏览器
            let evt = document.createEvent('MouseEvents')
            evt.initMouseEvent('click', true, true, window, 0, 0, 0, 0, 0, false, false, false, false, 0, null)
            el.dispatchEvent(evt)
          } else if (el.click) {
            // 对于不支持createEvent但有click方法的元素(例如旧IE)
            el.click()
          }
        })
      }
      return this // 支持链式调用
    }
  
    siblings(selector) {
      const siblings = []
      if (this.$els.length === 0) return new SfDocument([])
  
      this.$els.forEach(el => {
        let sibling = el.parentNode.firstChild
        while (sibling) {
          if (sibling.nodeType === 1 && sibling !== el && (!selector || sibling.matches(selector))) {
            siblings.push(sibling)
          }
          sibling = sibling.nextSibling
        }
      })
  
      return new SfDocument(siblings)
    }
  
    children(selector) {
      const children = []
      this.$els.forEach(el => {
        Array.from(el.children).forEach(child => {
          if (child.nodeType === 1 && (!selector || child.matches(selector))) {
            children.push(child)
          }
        })
      })
      return new SfDocument(children)
    }

    // 支持 直接绑定在元素上，也可以绑定在document上然后委托其后代元素执行
    on(eventType, selector, callback) {
      const nonBubbleEventsToBubbleEvents = {
        'focus': 'focusin',
        'blur': 'focusout'
      }
    
      const mouseEnterLeaveEventsToSimulate = {
        'mouseenter': 'mouseover',
        'mouseleave': 'mouseout'
      }
    
      // 替换为实际用于监听的事件类型
      if (nonBubbleEventsToBubbleEvents[eventType]) {
        eventType = nonBubbleEventsToBubbleEvents[eventType]
      } else if (mouseEnterLeaveEventsToSimulate[eventType]) {
        eventType = mouseEnterLeaveEventsToSimulate[eventType]
      }
    
      if (typeof selector === 'function') {
        // 如果只传入了事件类型和回调，则视为直接绑定，不做委托
        callback = selector
        selector = null
      }
    
      const handler = (e) => {
        const relatedTarget = e.relatedTarget
    
        if (!selector) {
          // 如果没有选择器，直接在事件目标上调用回调
          callback.call(e.target, e)
        } else {
          // 处理委托逻辑
          const potentialElements = new SfDocument(selector).$els
          const targetElement = e.target
    
          for (let elem of potentialElements) {
            if (elem.contains(targetElement)) {
              // 对 mouseover 和 mouseout 特殊处理以防止在子元素间移动时触发
              if ((eventType === 'mouseover' || eventType === 'mouseout') && (elem.contains(relatedTarget) || elem === relatedTarget)) {
                break
              }
              // 在首个匹配的被委托元素上调用回调，并结束循环
              callback.call(elem, e)
              break
            }
          }
        }
      }
    
      this.$els.forEach(el => el.addEventListener(eventType, handler))
    
      // 支持链式调用
      return this
    }
    
    append(htmlOrText) {
      if (this.$els.length === 0) return this // 没有元素时直接返回

      // 遍历所有元素，将字符串插入到每个元素的末尾
      this.$els.forEach(el => {
        // 使用 insertAdjacentHTML 在元素内容末尾插入 HTML 或文本字符串
        // insertAdjacentHTML 在插入大量 HTML 结构到 DOM 时通常比 innerHTML 更高效
        el.insertAdjacentHTML('beforeend', htmlOrText)
      })

      return this // 允许链式调用
    }

    is(selector) {
      if (this.$els.length === 0) return false // 没有元素时直接返回 false
      // 特别处理 ':focus'
      if (selector === ':focus') {
        return Array.from(this.$els).some(el => el === document.activeElement)
      } else {
        // 对于其他选择器，使用 Element.matches 方法
        return Array.from(this.$els).some(el => el?.matches(selector))
      }
    }

    static contains(ancestor, descendant) {
      return ancestor !== descendant && ancestor.contains(descendant)
    }

    parents(selector) {
      // 用于存放所有匹配元素的parents的数组
      let allParents = []
      this.$els.forEach(el => {
        let parents = []
        let currentElement = el.parentNode
        while(currentElement && currentElement !== document) {
          if(currentElement.matches(selector)) {
            parents.push(currentElement)
          }
          currentElement = currentElement.parentNode
        }
        allParents.push(...parents) // 将找到的parents加入总数组
      })
      // 返回包含所有找到parents的新SfDocument实例
      return new SfDocument(allParents.map(el => el.tagName.toLowerCase()).join(','))
    }
}

export default SfDocument

